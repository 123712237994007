import React from "react"
import Layout from "./../components/layout/layout.higherComponent"
// import Contact from '../components/Company/Contact/Contact'
import Head from "../components/head"
import Title from "../components/Company/Contact/title/title"
import Form from "../components/Company/Contact/form/form"

const ContactPage = () => {
  return (
    <Layout displayHeader="true">
      <Head title="Contact" />
      <div className="container-fluid">
        <div className="static">
          <Title />
          <Form />
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
