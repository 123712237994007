import React from "react"
import { withTranslation } from "react-i18next"

const title = props => {
  const { t } = props
  return (
    <header>
      <div className="container">
        <div className="page-title">
          <h1 style={{ color: "#000" }}>{t("src.pages.contactPage.title")}</h1>
        </div>
      </div>
    </header>
  )
}

export default withTranslation()(title)
